import ChatWidget from 'components/ChatWidget';
import Icon from './icono.png';
import Logo from './logo.png';

export default {
  logo: Logo,
  icon: Icon,
  title: 'Voluntariado',
  name: 'Civic House',
  exception: true,
  withoutLogin: true,
  catalogTalents: true,
  community: true,
  chatWidget: true,

};
